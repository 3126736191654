import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';

import classNames from 'classnames';
// import ContactButton from './ContactButton';
// import calculateGradientColor from '../../utils/calculateGradientColor';
import { DrawerContext } from '../../context/DrawerContext';
// import DrawerMenu from './DrawerMenu';
import globalStrings from '../../config/index';
import EnsembleLogo from './EnsembleLogo';
// import HamburgerIcon from './HamburgerIcon';

const NavBar = () => {
    const { isOpen, setIsOpen } = useContext(DrawerContext);
    const { logoAlt, home, services, portfolio, company, join, contact } =
        globalStrings.navigation;

    return (
        <div className="container mx-auto my-7 flex items-center justify-between w-full">
            <Link className="mr-6 flex items-center flex-shrink-0" to="/">
                <EnsembleLogo
                    alt={logoAlt}
                />
            </Link>
            <div className="flex items-center space-x-6">
                <Link
                    className="text-sm uppercase"
                    to="/services"
                    activeClassName=""
                    partiallyActive
                >
                    {services}
                </Link>
                <Link
                    className="text-sm uppercase"
                    to="/portfolio"
                    activeClassName=""
                    partiallyActive
                >
                    {portfolio}
                </Link>
                <Link
                    className="text-sm uppercase"
                    to="/company"
                    activeClassName=""
                    partiallyActive
                >
                    {company}
                </Link>
                <Link
                    className="text-sm uppercase"
                    to="/join"
                    activeClassName=""
                    partiallyActive
                >
                    {join}
                </Link>
            </div>
        </div>
    );
};

export default NavBar;