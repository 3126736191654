import React from 'react';
// import { useInView } from 'react-intersection-observer';

// import Footer from './Footer';
import NavBar from '../components/navbar/NavBar';
// import CookieNotification from './cookieNotification/CookieNotification';

type BodyPropsType = {
    children: JSX.Element | null;
};

const Layout = ({ children = null }: BodyPropsType) => {
    return (
        <div
            data-testid="layout-container"
            className="flex flex-col min-h-screen"
        >
            <NavBar />
            {/*<CookieNotification />*/}
            <main className="flex-grow">{children}</main>
            {/*<Footer />*/}
        </div>
    );
};

export default Layout;