import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useState,
} from 'react';

const DrawerContext = createContext({
    isOpen: false,
    setIsOpen: null as unknown as Dispatch<SetStateAction<boolean>>,
});

type BodyPropsType = {
    children: JSX.Element | null;
};

const DrawerContextProvider = ({ children }: BodyPropsType) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <DrawerContext.Provider value={{ isOpen, setIsOpen }}>
            {children}
        </DrawerContext.Provider>
    );
};

export { DrawerContextProvider, DrawerContext };